import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import React, { useState } from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import HomePage from "./mainpage";
import ContactUs from "./ContactUs";
import Productpage from "./product";

function App() {
  const [option, setOption] = useState("");

  return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage setOption={setOption} />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route
            path="/product"
            element={<Productpage option={option} setOption={setOption} />}
          />
        </Routes>
      </BrowserRouter>
  );
}

export default App;
