import React, { useRef } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import emailjs from "@emailjs/browser";

function ContactUs() {
  const form = useRef();
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    var templateParams = {
      name: "Rishabh Dev Karwa",
      notes: "Check this out im hero!",
    };
    emailjs
      .sendForm("service_osd14oe", "template_soeglve", form.current, {
        publicKey: "OrgMd5mzEh2DggWFJ",
      })
      .then(
        (result) => {
          console.log("Successfully send");
          emailjs
            .send("service_osd14oe", "template_soeglve", templateParams)
            .then(
              (response) => {
                console.log("SUCCESS!", response.status, response.text);
              },
              (error) => {
                console.log("FAILED...", error);
              }
            );
          form.current.reset();
        },
        (error) => {
          console.log("Unsuccessful" + error.text);
          form.current.reset();
        }
      );
  };
  return (
    <Container className="mt-5">
      <div style={{ margin: "60px" }}>
        <IoMdArrowRoundBack size={30} onClick={() => navigate("/")} />
      </div>
      <Row className="justify-content-md-center">
        <Col md="6" style={{ padding: "35px" }}>
          <h1>Contact Us</h1>
          <Form ref={form} onSubmit={handleSubmit}>
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                placeholder="Enter your name"
                type="text"
                name="user_name"
                // onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formEmail" className="mt-3">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                placeholder="Enter your email"
                type="email"
                name="user_email"
                // onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formMessage" className="mt-3">
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter your message"
                name="message"
                // onChange={handleChange}
              />
            </Form.Group>

            <Button
              style={{ backgroundColor: "orange" }}
              variant="primary"
              type="submit"
              className="mt-3"
              value="Send"
            >
              Submit
            </Button>
          </Form>
        </Col>
      </Row>
      <Row className="justify-content-md-center mt-3">
        <Col md="6" className="text-center">
          <a href="https://wa.me/+918968169361" className="btn btn-success">
            Contact us on WhatsApp
          </a>
        </Col>
      </Row>
    </Container>
  );
}

export default ContactUs;
